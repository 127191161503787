<template>
  <div class="AutoCall">
    哈哈哈
    <div >
      <a-time-picker v-model:value="value" />
      <a-time-picker v-model:value="strValue" valueFormat="HH:mm:ss" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch, ref } from "vue";
import moment from "moment";

export default {
  name: "AutoCall",
  components: {},
  setup() {
    const state = reactive({});

    const value = ref(moment("08:00:00", "HH:mm:ss"));
    const strValue = ref("09:00:00");
    watch(value, () => {
      console.log(value.value);
    });
    watch(strValue, () => {
      console.log(strValue.value);
    });
    return { ...toRefs(state), value, strValue, moment };
  },
};
</script>

<style scoped>
.AutoCall {
  height: 100%;
  background-color: #fff;
}
.sss {
  border: 1px solid #ccc;
}
.ant-time-picker {
  border: none;
}
</style>